import { motion } from 'framer-motion';
import { FC, PropsWithChildren, useEffect } from 'react';

import styles from './Modal.module.scss';

const Modal: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      {children}
    </motion.div>
  );
};

export default Modal;
